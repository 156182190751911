import $ from 'jquery';
$.fn.showTabItem = function(){
	let selector = '.nav-tabs-link';

    $(selector).on('click', function(e){
        e.preventDefault;
        let id = $(this).attr('data-id');       
        if($('#'+id).hasClass('show') && !($('#'+id).hasClass('active')) ){
            $(this).removeClass('active')   
        }
        if(!($('#'+id).hasClass('show'))){
            $('#'+id).addClass('show active')
        }       
    });
}

$(function(){	
	$(this).showTabItem();
})